export const scrollToId = (id, offset = 0) => {
  // Remove the '#' if it exists
  const cleanId = id.startsWith('#') ? id.substring(1) : id
  const element = document.getElementById(cleanId)

  if (element) {
    // Check if there's a tabs header wrapper and get its height
    const tabsHeader = document.getElementById('tabs-header-wrapper')
    const tabsHeaderHeight = tabsHeader ? tabsHeader.offsetHeight : 0

    // Calculate the total offset including the tabs header
    const totalOffset = offset + tabsHeaderHeight

    // Scroll to the element with the adjusted offset
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
    window.scrollTo({
      top: elementPosition - totalOffset,
      behavior: 'smooth',
    })

    return true
  }

  return false
}

// Function to handle URL hash on page load
export const handleUrlHash = () => {
  if (typeof window !== 'undefined' && window.location.hash) {
    // Use window.requestAnimationFrame for proper scoping
    window.requestAnimationFrame(() => {
      // Get the default offset based on any fixed headers in the layout
      const defaultOffset = document.querySelector('header')?.offsetHeight || 0
      scrollToId(window.location.hash, defaultOffset)
    })
  }
}

// Auto-initialize the hash handling if we're in a browser environment
// Only add these listeners if they haven't been added before
if (typeof window !== 'undefined' && !window.__hashListenersInitialized) {
  // Handle hash on initial page load
  window.addEventListener('DOMContentLoaded', () => {
    // Only auto-handle hash if we're not on a page with tabs
    // If we're on a page with tabs, the Tabs component will handle it
    if (!document.getElementById('tabs-header-wrapper')) {
      handleUrlHash()
    }
  })

  // Also handle hash changes during navigation
  window.addEventListener('hashchange', () => {
    // For hash changes, we still want to handle it even on pages with tabs
    window.requestAnimationFrame(handleUrlHash)
  })

  // Mark that we've initialized the listeners to prevent duplicates
  window.__hashListenersInitialized = true
}
