import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box, Typography, Button, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { styled } from '@mui/material/styles'
import ContentGroup from '../ContentGroup'
import { handleUrlHash } from './helpers'

const TabsWrapper = styled(Box)(({ theme, displayDesktop, displayMobile }) => ({
  display: displayDesktop !== false ? 'block' : 'none',
  [theme.breakpoints.down('sm')]: {
    display: displayMobile !== false ? 'block' : 'none',
  },
}))

const TabsHeaderWrapper = styled(Box)(({ theme, showMeganavMobile, showToolbar, hideInfoBanner }) => ({
  position: 'sticky',
  textAlign: 'center',
  top: `${111 - (hideInfoBanner ? 40 : 0)}px`,
  transition: '0.1s ease-in-out',
  zIndex: 10,
  paddingTop: '8px',
  '&.sticky-on-top': {
    backgroundColor: theme.palette.common.white,
    boxShadow: '-2px 2px 3px 0px rgba(0, 0, 0, 0.20)',
    margin: '0px -15px',
    paddingLeft: '12px',
    paddingRight: '12px',
    [theme.breakpoints.up('sm')]: {
      margin: '0px -500px',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  [theme.breakpoints.down('sm')]: {
    display: 'block',
    top: `${124 - (showMeganavMobile ? 0 : 48) - (showToolbar ? 0 : 16)}px`,
    backgroundColor: theme.palette.common.white,
    margin: '0px -15px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
}))

const TabsHeader = styled(Box)(({ theme, showMeganavMobile, hideInfoBanner }) => ({
  margin: '0',
  zIndex: 100,
  display: 'inline-block',
  maxWidth: '100%',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  backgroundColor: '#F5F5F5',
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.20) inset',
  borderRadius: '50px',
}))

const TabButtons = styled(Box)(({ theme, multipleTabs, value, tabsLength }) => ({
  [theme.breakpoints.down('sm')]: {
    marginRight: multipleTabs ? '-15px' : null,
  },
  margin: '0 auto',
  display: 'flex',
  position: 'relative',
  gap: '16px',
  width: 'fit-content',
  padding: '6px 8px',
  '*': {
    zIndex: 2,
  },
  '& > button': {
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: 'unset',
    minWidth: '0',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: '44px',
    padding: '0 20px',
    fontSize: '1.25rem',
    fontWeight: 600,
    borderRadius: '50px',
    cursor: 'pointer',
    transition: 'color 0.15s ease-in',
    color: theme.palette.primary.dark,
    '&.checked': {
      color: theme.palette.common.white,
      zIndex: 11,
    },
    [theme.breakpoints.down('sm')]: {
      height: '41px',
      fontSize: '1.125rem',
    },
  },
  '.indicator': {
    position: 'absolute',
    display: 'flex',
    height: '44px',
    backgroundColor: '#0053A0',
    zIndex: 10,
    borderRadius: '50px',
    transition: '0.15s ease-out',
    [theme.breakpoints.down('sm')]: {
      height: '41px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.125rem',
    padding: '4px 6px',
  },
}))

const TabPanelStyles = styled('div')(({ theme }) => ({
  margin: '20px 0',
  '& .collection-buttons a': {
    padding: '5px 10px',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '0',
  },
}))

const TabPanel = ({ children, value, index, className }) => (
  <TabPanelStyles
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    className={className}
  >
    <Box sx={{ p: 0, ...(value === index ? { display: 'block' } : { display: 'none' }) }}>
      <Typography>{children}</Typography>
    </Box>
  </TabPanelStyles>
)

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
}

const StrapiTabs = ({ data }) => {
  const [tabBoundary, setTabBoundary] = useState({})
  const tabButtonsRef = useRef(null)
  const tabsHeader = useRef(null)
  const TabsData = data?.TabGroup?.Tabs
  const multipleTabs = TabsData?.length > 3
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { showMeganavMobile, hideInfoBanner } = useSelector(state => state.global)
  const [initialTabSet, setInitialTabSet] = useState(false)
  const [value, setValue] = useState(0)

  const showToolbar =
    typeof document !== 'undefined' && !document?.getElementById('mobile-toolbar')?.classList?.contains?.('hidden')

  useLayoutEffect(() => {
    function getTabPosition() {
      const divBoundaries = tabsHeader.current.getBoundingClientRect()
      const targetDiv = tabsHeader.current
      if (divBoundaries.top > 0) {
        if (divBoundaries.top <= 111) {
          targetDiv.classList.add('sticky-on-top')
        } else {
          targetDiv.classList.remove('sticky-on-top')
        }
      }
    }
    window.addEventListener('scroll', getTabPosition)
    return () => window.removeEventListener('scroll', getTabPosition)
  }, [])

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const maxTabs = data?.TabGroup?.Tabs?.length
      const params = new URLSearchParams(window.location.search)
      const tabParam = Number(params.get('tab')) - 1
      const tabExists = tabParam < maxTabs && tabParam >= 0

      if (typeof tabParam === 'number' && tabExists) {
        setValue(tabParam)
        setInitialTabSet(true)
      } else if (window.location.hash) {
        setInitialTabSet(true)
      } else {
        window.scrollTo(0, 0)
      }
    }
  }, [data])

  useLayoutEffect(() => {
    const selectedTab = Array.from(tabButtonsRef?.current?.children || [])?.filter(child => child?.type === 'button')?.[
      value
    ]
    if (selectedTab) {
      setTabBoundary({ width: selectedTab.offsetWidth, left: selectedTab.offsetLeft })
    }
  }, [data, value, isMobile])

  useEffect(() => {
    if (initialTabSet && window.location.hash && typeof window !== 'undefined') {
      window.requestAnimationFrame(() => {
        handleUrlHash()
      })
    }
  }, [initialTabSet, value])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <TabsWrapper
      data-testid={data?.Tabs?.testId}
      displayDesktop={data?.TabGroup?.DisplayDesktop}
      displayMobile={data?.TabGroup?.DisplayMobile}
    >
      <TabsHeaderWrapper
        id="tabs-header-wrapper"
        ref={tabsHeader}
        showMeganavMobile={showMeganavMobile}
        showToolbar={showToolbar}
        hideInfoBanner={hideInfoBanner}
      >
        <TabsHeader>
          <TabButtons
            ref={tabButtonsRef}
            className="tabs-container"
            aria-label="tabs"
            multipleTabs={multipleTabs}
            value={value}
            tabsLength={data?.TabGroup?.Tabs?.length}
          >
            {TabsData &&
              TabsData.map((tab, index) => (
                <Button
                  key={`tab-${tab.TabName}`}
                  role="tab"
                  name={tab.TabName}
                  className={classNames(`tab-button ${data?.TabGroup?.testId}-${index}`, { checked: index === value })}
                  label={tab.TabName}
                  onClick={e => handleChange(e, index)}
                  aria-selected={index === value}
                >
                  {tab.TabName}
                </Button>
              ))}
            <span class="indicator" style={{ ...tabBoundary }} />
          </TabButtons>
        </TabsHeader>
      </TabsHeaderWrapper>
      {TabsData &&
        TabsData.map((tab, index) => (
          <TabPanel key={`tab-panel-${tab.TabName}`} value={value} index={index}>
            <ContentGroup data={tab?.ContentGroup} contentTab={tab.TabName !== null} />
          </TabPanel>
        ))}
    </TabsWrapper>
  )
}

export default React.memo(StrapiTabs)

StrapiTabs.propTypes = {
  data: PropTypes.object,
}

export const tabGroupFragment = graphql`
  fragment StrapiPageTabGroupFragment on StrapiTabGroup {
    id
    Title
    testId
    Tabs {
      TabName
      ContentGroup {
        ...StrapiContentGroupFragment
      }
    }
    DisplayDesktop
    DisplayMobile
  }
`
